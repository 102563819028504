import consumer from "./consumer"

$(document).on('turbolinks:load', function () {
  let event;
  if ($('#event_id').length > 0) {
    event = $('#event_id').data('video');
  } else {
    event = $('#video').data('video');
  }
  if (event) {
    consumer.subscriptions.create({channel: "ChatChannel", event: event}, {
      received(data) {
        if (data.status === 'new_notification') {
          if (data.level === 'critical') {
            alert(data.text);
          } else if (data.level === 'important') {
            Swal.fire({
              title: 'Повідомлення',
              text: data.text,
              icon: 'info',
              confirmButtonText: 'Прочитано'
            });
          } else {
            M.toast({html: data.text, displayLength: 3000, classes: 'rounded'})
          }
        }
        if (data.status === 'questions_false') {
          $('#questionnaire > a').removeClass('menu_highlight');
        } else if (data.status === 'questions_true') {
          $('#questionnaire > a').addClass('menu_highlight');
          Swal.fire({
            title: 'Опитування активовано',
            text: "Ви можете перейти до опитування",
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'Закрити',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Перейти до опитування'
          }).then((result) => {
            if (result.isConfirmed) {
              window.open(window.location.origin + '/questionnaire');
            }
          })
        }

        if (data.status === 'chat_false') {
          $('#message_text').prop('disabled', true);
          $('#chat_send_message').addClass('disabled');
        } else if (data.status === 'chat_true') {
          $('#message_text').prop('disabled', false);
          $('#chat_send_message').removeClass('disabled');
        }

        if (data.status === 'new_chat_message') {
          $('#messages').prepend(data.message);
          if ($('#messages li').length > 50) {
            $('#messages li').last().remove();
          }
        } else if (data.status === 'destroy') {
          $('#message_' + data.m_id).remove();
        }

        if (data.status === 'add_speaker_question') {
          $('#speaker_answers').prepend(data.message);
        }
        if (data.status === 'remove_speaker_question') {
          $('#question_' + data.message).remove();
        }

        if (data.status === 'activated') {
          $('#new_poll').html(data.poll);
          $('#polls').collapsible('open', 0);
          Swal.fire({
            title: 'Запитання',
            icon: 'warning',
            text: 'Активовано запитання',
            showCancelButton: true,
            confirmButtonText: 'Перейти',
            cancelButtonText: 'Закрити'
          }).then((result) => {
            if (result.value) {
              let polls = document.getElementById("polls");
              polls.scrollIntoView();
            }
          });
        } else if (data.status === 'deactivated') {
          M.toast({html: "Час опитування закінчився!", displayLength: 3000, classes: 'rounded'});
          $('#new_poll').html('');
          $('#polls').collapsible('close', 0);
          $('#polls_statistics_total').html(data.statistics)
        }

        if (data.status === 'quiz_activated') {
          $('#quiz').collapsible('open', 0);
          $('#new_quiz').html(data.quiz);
          Swal.fire({
            title: 'Вікторина',
            icon: 'warning',
            text: 'Активовано вікторину',
            showCancelButton: true,
            confirmButtonText: 'Перейти',
            cancelButtonText: 'Закрити'
          }).then((result) => {
            if (result.value) {
              let quiz = document.getElementById("quiz");
              quiz.scrollIntoView();
            }
          });
        } else if (data.status === 'quiz_deactivated') {
          $('#new_quiz').html('');
          M.toast({html: "Вікторину завершено!", displayLength: 3000, classes: 'rounded'});
        }
      }
    });
  }
});
