import consumer from "./consumer"

$(document).on('turbolinks:load', function () {
  let admin = $('#admin').data('admin');
  if (admin) {
    consumer.subscriptions.create({channel: "AdminChannel", admin: admin}, {
      received(data) {
        let count = 0;
        if (data.status === 'new_correct_answer') {
          $('#correct_answers').append(data.answer);
          count = parseInt($('#correct_answer_count').html());
          $('#correct_answer_count').html(++count);
        }
        if (data.status === 'new_incorrect_answer') {
          $('#incorrect_answers').append(data.answer);
          count = parseInt($('#incorrect_answer_count').html());
          $('#incorrect_answer_count').html(++count);
        }
        if (data.status === 'new_message') {
          $('#messages_video_' + data.video).prepend(data.message);
          $('#messages_count').html(parseInt($('#messages_count').html()) + 1);
        }
        if (data.status === 'new_question') {
          $('#questions_video_' + data.video).prepend(data.message);
          $('#questions_count').html(parseInt($('#questions_count').html()) + 1);
        }
      }
    });
  }
});
